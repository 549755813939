@import "../../../components/Layout/common";

.warehouse-list {
  --padding-bottom: 19px;
  --padding-left: 32px;
  --padding-right: 24px;
  --padding-top: 19px;
  --padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  --right-column-margin: 32px;
  --right-column-width: 320px;

  .flex();
  .flex-column();

  .action-icon {
    cursor: pointer;
  }

  &-header {
    .flex();
    .flex-row();
    .flex-justify-content-space-between();
    .flex-align-items-center();

    border-bottom: var(--border-light-gray) solid 1px;
    height: 82px;
    padding: var(--padding);

    &-title {
      .flex-grow-1();

      color: var(--color-sidebar-btn-default);
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
    }

    &-search {
      .flex();
      .flex-grow-1();
      .flex-justify-content-center();
      margin-right: 12px;

      .ant-input-affix-wrapper {
        background-color: var(--color-light-gray);
        border-color: var(--color-light-gray);
        border-radius: var(--button-border-radius);
        border-style: solid;
        color: rgba(112, 112, 112, 0.6);
        height: 44px;
        padding: 12px 18px;
        width: 435px;

        .ant-input-prefix {
          height: 20px;
          width: 20px;

          svg {
            height: 100%;
            width: 100%;
          }
        }

        .ant-input {
          background-color: inherit;
        }
      }
    }

    &-new-warehouse {
      .flex();
      .flex-grow-1();
      .flex-justify-content-end();

      .ant-dropdown-button {
        border-color: var(--color-sidebar-btn-active);
        border-radius: var(--button-border-radius);
        border-style: solid;
        border-width: 0;
        color: var(--color-sidebar-btn-active);

        .ant-btn-default {
          border-color: inherit;
          border-top-left-radius: var(--button-border-radius) !important;
          border-bottom-left-radius: var(--button-border-radius) !important;
          color: inherit;
        }

        .ant-btn-icon-only {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-top-right-radius: var(--button-border-radius) !important;
          border-bottom-right-radius: var(--button-border-radius) !important;
          padding-top: 10px;

          .anticon svg {
            width: 16px;
            height: auto;
          }
        }
      }
    }

    &-avatar {
      .flex();
      .flex-justify-content-end();

      width: var(--right-column-width);
    }
  }

  &-content {
    .flex();
    .flex-row();

    &-list {
      .flex();
      .flex-grow-1();
      .flex-column();
      gap: 6px;
      padding: var(--padding);

      &-item {
        .flex();
        .flex-align-items-center();

        background: var(--color-light-gray);
        border-radius: var(--button-border-radius);
        color: rgba(112, 112, 112, 1);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        min-height: 44px;
        padding: 12px 18px;

        &.selected {
          background: var(--border-light-gray);
        }

        &-name {
          .flex-grow-1();
        }

        &-versions {
          .flex();
          gap: 10px;
        }
      }
    }

    &-versions {
      .flex();
      .flex-column();
      box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.12);
      gap: 10px;
      min-height: calc(100vh - 82px);
      padding: 24px 20px;
      width: var(--right-column-width);

      &-last {
        .flex();
        .flex-column();
        gap: 10px;
      }

      &-history {
        margin-top: 24px;

        &-title {
          border-bottom: solid 1px #b4b4b4;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 25px;
          padding-bottom: 17px;
        }

        .ant-timeline {
          padding-left: 8px;
          padding-top: 8px;

          &-item {

          }
        }
      }

      .delete-icon {
        cursor: pointer;
      }
    }
  }
}