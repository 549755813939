.length-input {
  align-items: center;
  background: var(--color-light-gray);
  border: solid 1px var(--color-light-gray);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-items: stretch;

  .ant-input-number {
    background: var(--color-light-gray);
    border: solid 1px var(--color-light-gray);
    color: var(--color-sidebar-btn-default);
    flex-grow: 1;
    width: 100%;

    .ant-input-number-input {
      padding: 0 25px 0 11px;
      text-align: right;
    }
  }
}
