@import "../../../components/Layout/common";

.warehouse-schema-button {
  .flex();
  .flex-align-items-center();
  .flex-row();
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: var(--button-border-radius);
  gap: 6px;
  padding: 5px 8px;

  &-icon {
    .flex();
    .flex-align-items-center();

    &-svg {
      height: 24px;
      width: 24px;
    }
  }

  &-data {
    .flex();
    .flex-column();
    .flex-justify-content-center();
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;

    &-is-active.active {
      color: rgba(215, 0, 0, 1);
    }
  }
}
