.count-input {
  .ant-input-number {
    background: var(--color-light-gray);
    border: solid 1px var(--color-light-gray);
    border-radius: 6px;
    color: var(--color-sidebar-btn-default);
    width: 100%;

    .ant-input-number-input {
      padding: 0 25px 0 11px;
      text-align: right;
    }
  }
}
