div {
  text-shadow: none;
}

:root {
  --border-light-gray: #d8d8d8;
  --button-border-radius: 6px;
  --color-blue: rgba(47, 128, 237, 1);
  --color-light-gray: #F3F3F3;
  --color-gray: #9E9E9E;
  --color-input: #55595C;
  --color-sidebar-btn-default: #707070;
  --color-sidebar-btn-active: #2F80ED;
  --color-toolbar-btn-default: black;
  --color-toolbar-btn-active: white;
  --dialog-border-radius: 14px;
  --hug-width: 64px;
  --toolbar-button-size: 44px;
  --top-bar-height: 82px;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-stretch {
  align-items: stretch;
}

.flex-align-self-center {
  align-self: center;
}

.flex-justify-content-center {
  justify-content: center;
}

.flex-justify-content-space-between {
  justify-content: space-between;
}

.flex-justify-content-start {
  justify-content: flex-start;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.ant-modal {
  .ant-modal-content {
    border-radius: var(--dialog-border-radius);

    .ant-modal-header {
      border-radius: var(--dialog-border-radius);
    }

    .ant-modal-body {
      border-top-left-radius: var(--dialog-border-radius);
      border-top-right-radius: var(--dialog-border-radius);
    }

    .ant-modal-footer {
      border-bottom-left-radius: var(--dialog-border-radius);
      border-bottom-right-radius: var(--dialog-border-radius);
      border-top: none;
      margin-top: 32px;
      padding: 0 24px 24px 24px;
      text-align: left;
      .ant-btn {
        background-color: #F3F3F3;
        border-radius: var(--button-border-radius);
        border-color: #F3F3F3;
        color: #2F80ED;

        &-dangerous {
          &.ant-btn-primary {
            background-color: #ff4d4f;
            border-color: #ff4d4f;
            color: #FFFFFF;
          }
        }

        &-primary {
          background-color: #2F80ED;
          border-color: #2F80ED;
          color: #FFFFFF;

          &:disabled {
            background-color: var(--color-light-gray);
            border-color: var(--color-gray);
            color: var(--color-gray);
          }
        }
      }
    }
  }
}

.ant-btn {
  border-radius: var(--button-border-radius);
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  margin: 8px;
  border-radius: 50%;
  display: inline-block;
}

.dot-sm {
  height: 6px;
  width: 6px;
  background-color: #bbb;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 8px;
  display: inline-block;
}