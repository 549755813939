.project-settings-modal {
  .flex();
  .flex-column();

  color: var(--color-sidebar-btn-default);
  gap: 16px;

  &-row {
    .flex();
    .flex-row();
    gap: 24px;

    &-item {
      .flex();
      .flex-column();
      .flex-grow-1();
      gap: 8px;

      &-title {
        font-size: 12px;
        line-height: 20px;
      }

      &-content {
        .flex();
        .flex-row();
        .flex-align-items-center();

        gap: 8px;

        .ant-select {
          color: var(--color-sidebar-btn-default);

          .ant-select-selector {
            background-color: var(--color-light-gray);
            border: 1px solid var(--color-light-gray);
            border-radius: 6px;
            font-size: 14px;
            height: 32px;
            line-height: 20px;
          }

          .ant-select-arrow {
            color: var(--color-sidebar-btn-default);
          }
        }

        .ant-radio-group, .ant-radio-group-solid {
          .flex() !important;
          .flex-row();

          gap: 4px;

          .ant-radio-button-wrapper {
            background: var(--color-light-gray);
            border: solid 1px var(--color-light-gray);
            border-radius: 6px;
            color: var(--color-sidebar-btn-default);
            text-align: center;
            width: 80px;

            &:not(:first-child)::before {
              display: none;
            }

            &-checked {
              border: solid 1px var(--color-sidebar-btn-active);
              color: var(--color-sidebar-btn-active);
            }
          }
        }

        .ant-input-number, .ant-input {
          background: var(--color-light-gray);
          border: solid 1px var(--color-light-gray);
          border-radius: 6px;
          color: var(--color-sidebar-btn-default);
        }
      }
    }

    &.warehouse-source {
      gap: 8px;

      .ant-radio-button-wrapper {
        background: var(--color-light-gray);
        border: solid 1px var(--color-light-gray);
        border-radius: 6px;
        color: var(--color-sidebar-btn-default);
        display: block;
        flex-grow: 1;
        text-align: center;

        &:not(:first-child)::before {
          display: none;
        }

        &-checked {
          border: solid 1px var(--color-sidebar-btn-active);
          color: var(--color-sidebar-btn-active);
        }
      }
    }
  }
}