@import "./common";
@import "../../scenes/Planner/components/ProjectSettingsModal/ProjectSettingsModal";
@import "../../scenes/Planner/components/WarehouseList";
@import "../../scenes/Planner/components/WarehouseSchemaButton";
@import "../../scenes/Planner/components/WarehouseSchemaDescription";
@import "../../scenes/Planner/components/WarehouseSchemaHistoryItem";
@import "../../scenes/Planner/components/LengthInput/LengthInput";
@import "../../scenes/Planner/components/CountInput/CountInput";
@import "../../scenes/Planner/react-planner/src/styles/attributes";
@import "../../scenes/Planner/react-planner/src/styles/qr-codes";
@import "../ToggleButton/_toggleButton";


.skladar-layout {
  .flex();
  .flex-row();
  min-height: 100vh;

  &-content {
    .flex-grow-1();
  }

  .skladar-sidebar {
    .flex();
    .flex-column();
    .flex-align-items-stretch();
    border-right: solid 1px var(--border-light-gray);
    gap: 40px;
    padding-bottom: 25px;
    width: var(--hug-width);

    a {
      .flex();
      .flex-align-items-center();
      .flex-justify-content-center();


      svg {
        .stroke {
          stroke: var(--color-sidebar-btn-default);
        }

        .fill {
          fill: var(--color-sidebar-btn-default);
        }
      }

      &:hover, &.active, .active & {
        svg {
          .stroke {
            stroke: var(--color-sidebar-btn-active);
          }

          .fill {
            fill: var(--color-sidebar-btn-active);
          }
        }
      }
    }

    .logo {
      height: var(--top-bar-height);
    }
  }

  .toolbar {
    border-bottom: solid 1px var(--border-light-gray);
    height: var(--top-bar-height);

    .flex();
    .flex-row();
    .flex-align-items-center();

    .skladar-toolbar {
      .flex();
      .flex-row();
      .flex-align-items-center();
      gap: 4px;
      padding-left: 25px;
      width: 100%;

      .button, div {
        svg {
          .stroke {
            stroke: var(--color-toolbar-btn-default);
          }

          .fill {
            fill: var(--color-toolbar-btn-default);
          }
        }

        &:hover, &.active, &.open {
          svg {
            .stroke {
              stroke: var(--color-toolbar-btn-active);
            }

            .fill {
              fill: var(--color-toolbar-btn-active);
            }
          }
        }
      }

      .button, input {
        .flex();
        .flex-align-items-center();
        .flex-justify-content-center();

        background-color: var(--color-light-gray);
        border: none;
        border-radius: var(--button-border-radius);
        color: var(--color-input);
        height: var(--toolbar-button-size);
        width: var(--toolbar-button-size);

        svg {
          g {
            mask {
              mask-type: alpha;
            }
          }
        }
      }

      .project-name {
        position: relative;

        &-input {
          background-color: var(--color-light-gray);
          padding: 12px 18px;
          width: 366px;
        }

        &-status {
          border-radius: var(--button-border-radius);
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          padding: 4px 12px;
          position: absolute;
          right: 8px;
          top: 8px;

          &.active {
            background-color: #d70000;
          }

          &.draft {
            background-color: #A7A7A7;
          }
        }
      }

      .placeholder {
        width: 20px;

        &-full {
          .flex-grow-1();
        }
      }

      .dropdown-button {
        .flex();
        .flex-column();
        cursor: pointer;
        position: relative;
        width: var(--hug-width);

        &.hamburger-button {
          width: 44px;

          >div {
            color: #707070;
            padding: 12px 12px 12px 18px;

            .for-checkbox {
              .flex();
              .flex-align-items-center();

              .checkbox {
                display: inline;
                height: 16px;
                margin-right: 10px;
                width: 16px;
              }
            }

            .divider {
              background-color: #EEEEEE;
              height: 1px;
              padding: 0;
            }

            .button {
              font-size: 16px;
              line-height: 20px;
              padding: 8px 10px;

              &:hover {
                background-color: transparent;
                color: #2F80ED;
              }
            }
          }
        }

        &.open, &:hover {
          background: var(--color-blue);
        }

        .icons {
          .flex();
          .flex-row();
        }

        .children {
          .flex();
          .flex-column();
          background-color: #fff;
          border-radius: var(--button-border-radius);
          box-shadow: 0 6px 32px 0 rgba(0, 0, 0, 0.24);
          color: var(--color-input);
          left: 0;
          position: absolute;
          top: calc(var(--toolbar-button-size) + 4px);
          width: 100px;
          z-index: 10000;

          .button {
            background: transparent;
            border: none;
            cursor: pointer;
            font-size: 12px;
            gap: 8px;
            line-height: 24px;
            padding: 10px 10px;
            text-align: left;
            width: 100%;
            .flex();
            .flex-row();
            .flex-justify-content-start();
            .flex-align-items-center();

            svg {
              .fill {
                fill: var(--color-toolbar-btn-default);
              }
              .stroke {
                stroke: var(--color-toolbar-btn-default);
              }
            }

            &:hover {
              background-color: var(--color-sidebar-btn-active);
              color: var(--color-toolbar-btn-active);

              svg {
                .fill {
                  fill: var(--color-toolbar-btn-active);
                }
                .stroke {
                  stroke: var(--color-toolbar-btn-active);
                }
              }
            }
          }
        }
      }

      &-additional {
        .flex();
        .flex-row();

        width: 320px;
      }
    }
  }

  .copy-button {
    background-color: #2F80ED;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    width: 100%;

    &:disabled {
      background-color: #d8d8d8;
    }
  }
}

.attributes-table {
  margin-bottom: 16px;
  width: 100%;
}

.attributes-table td {
  padding-top: 16px;
}
@primary-color: #1890FF;@link-color: #1DA57A;