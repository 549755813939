.qr-codes {
  .ant-btn {
    &-primary {
      background: rgba(47, 128, 237, 0.06);
      border: rgba(47, 128, 237, 0.06);
      color: rgba(47, 128, 237, 1);
      font-size: 10px;
      height: 100%;
      line-height: 20px;
      width: 100%;
    }
  }

  svg {
    height: 20px;
    width: 20px;

    .stroke {
      stroke: var(--color-sidebar-btn-default);
    }

    .fill {
      fill: var(--color-sidebar-btn-default);
    }
  }

  .active {
    svg {
      .fill {
        fill: var(--color-sidebar-btn-active);
      }
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px;

    .ant-dropdown-menu-item {
      align-items: center;
      color: var(--color-sidebar-btn-default);
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 10px;
      line-height: 20px;
    }
  }
}
