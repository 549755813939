@import "../../../components/Layout/common";

.warehouse-schema-history-item {
  .flex();
  .flex-column();
  font-size: 10px;
  line-height: 12px;
  gap: 8px;

  &:hover {
    .warehouse-schema-history-item-info {
      &-actions {
        .flex();
      }
    }
  }

  &-info {
    .flex();
    .flex-row();
    .flex-justify-content-space-between();
    color: rgba(112, 112, 112, 1);

    &-data {
      &-date {
        font-weight: 400;
      }
      &-status {
        font-weight: 500;

        &.active {
          color: rgba(215, 0, 0, 1);
        }
      }
    }
    &-actions {
      display: none;
      .flex-row();
      gap: 8px;
      top: 12px;
      right: 0;
      position: absolute;

      div {
        cursor: pointer;
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: #2F80ED;
        }

        g {
          g {
            rect {
              fill: #2F80ED;
            }
          }
        }
      }
    }
  }

  &-name {
    color: rgba(66, 66, 66, 1);
  }
}