.toggle-button {
  background-color: var(--color-light-gray);
  border: solid 1px var(--color-light-gray);
  border-radius: var(--button-border-radius);
  color: var(--color-input);
  cursor: pointer;
  padding: var(--button-border-radius);
  height: 32px;
  width: 32px;

  &:disabled {
    cursor: default;
  }

  &.active {
    border-color: var(--color-sidebar-btn-active);
    color: var(--color-sidebar-btn-active);

    svg {
      .stroke {
        stroke: var(--color-sidebar-btn-active);
      }

      .fill {
        fill: var(--color-sidebar-btn-active);
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;

    .stroke {
      stroke: var(--color-sidebar-btn-default);
    }

    .fill {
      fill: var(--color-sidebar-btn-default);
    }
  }
}