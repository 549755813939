.attributes {
  .flex();
  .flex-column();
  gap: 6px;
  margin-top: 6px;


  &-row {
    .flex();
    .flex-row();
    .flex-align-items-center();

    &-title {
      .flex-grow-1();
      color: var(--color-sidebar-btn-default);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &-value {
      width: 168px;

      &.walls-attributes {
        .flex();
        .flex-row();
        gap: 2px;
      }
    }
  }

  .shelves-and-cells {
    margin-top: 16px;

    &-header {
      .flex();
      .flex-row();
      .flex-align-items-center();
      gap: 16px;

      &-title {
        flex-grow: 0;
        font-size: 10px;
        font-weight: 600;
        width: auto;
      }

      &-divider {
        .flex-grow-1();

        hr {
          border: solid 1px var(--border-light-gray);
          border-bottom-width: 0;
          color: var(--color-light-gray);
        }
      }

      &-toggle-editing {
        .toggle-button {
          height: 20px;
          padding: 3px;
          width: 20px;

          svg {
            height: 12px;
            width: 12px;

            .stroke {
              stroke: var(--color-sidebar-btn-active);
            }

            .fill {
              fill: var(--color-sidebar-btn-active);
            }
          }

          &.active {
            background-color: var(--color-sidebar-btn-active);
            color: white;

            svg {
              .stroke {
                stroke: white;
              }

              .fill {
                fill: white;
              }
            }
          }
        }
      }
    }

    &-body {

      svg {
        height: 12px;
        width: 12px;

        .stroke {
          stroke: var(--color-sidebar-btn-default);
        }

        .fill {
          fill: var(--color-sidebar-btn-default);
        }
      }

      &-auto {
        .flex();
        .flex-column();

        &-row {
          .flex();
          .flex-row();
          gap: 8px;

          &-value {
            .flex();
            .flex-column();
            gap: 2px;

            &-title {
              color: var(--color-sidebar-btn-default);
              font-size: 10px;
              line-height: 20px;
            }
          }
        }
      }

      &-manual {
        .flex();
        .flex-column();
        margin-top: 12px;
        gap: 12px;

        &-add {
          .flex();
          .flex-align-items-center();
          .flex-row();
          .flex-justify-content-start();
          &-button {
            .flex-grow-1();

            background-color: rgba(47, 128, 237, 0.06);
            border-radius: var(--button-border-radius);
            border-width: 0;
            color: var(--color-sidebar-btn-active);
            cursor: pointer;
            font-size: 10px;
            font-weight: 400;
            height: 32px;
            line-height: 20px;
            padding: 6px 12px 6px 6px;

            &:disabled {
              cursor: default;
            }
          }
        }

        &-shelf {
          .flex();
          .flex-column();
          gap: 6px;

          &-settings {
            .flex();
            .flex-row();
            .flex-align-items-center();

            background-color: var(--color-light-gray);
            border: solid 1px var(--color-light-gray);
            border-radius: var(--button-border-radius);
            color: var(--color-sidebar-btn-default);
            font-size: 12px;
            line-height: 16px;
          }

          .toggle-button {
            padding: 3px 0 0;

            &.active {
              border-color: var(--color-light-gray);
            }

            svg {
              height: 20px;
              width: 20px;
            }
          }

          &-number {

          }

          &-height-icon, &-thickness-icon {
            margin-left: 5px;
            padding-top: 4px;
            width: 12px;
          }

          &-thickness-icon {
            padding-top: 5px;
          }

          &-height-value, &-thickness-value {
            width: 80px;

            input {
              height: 100%;
              padding: 0;
            }
          }

          &-thickness-value {
            margin-left: 1px;
          }

          &-height-unit, &-thickness-unit {
            width: 40px;
          }

          &-divider {
            .flex-grow-1();
          }

          &-delete-icon {
            .toggle-button {
              padding-top: 0;
              svg {
                height: 8px;
                width: 8px;
              }
            }
          }

          &-sections {
            .flex();
            .flex-column();

            gap: 6px;
            padding-left: 20px;

            &-settings {

            }
            &-cells {

            }
          }

          &-section-boxes {
            .flex();
            .flex-align-items-center();
            .flex-row();
            gap: 8px;
            padding-left: 15px;

            &-title {
              .flex-grow-1();

              color: var(--color-sidebar-btn-default);
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }

            &-count {
              width: 61px;
            }

            &-buttons {
              .flex();
              .flex-row();
              gap: 2px;

              .toggle-button {
                font-size: 14px;
                line-height: 20px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
