@import "../../../components/Layout/common";

.warehouse-schema-description {
  .flex();
  .flex-column();
  background-color: rgba(47, 128, 237, 0.01);
  border-radius: var(--button-border-radius);
  border-color: rgba(47, 128, 237, 0.06);
  border-style: solid;
  border-width: 1px;

  &-info {
    .flex();
    .flex-column();
    gap: 12px;

    &-data {
      .flex();
      .flex-row();
      gap: 6px;
      padding: 12px 12px 0 12px;

      &-icon {

      }

      &-status {
        .flex();
        .flex-column();
        color: rgba(112, 112, 112, 1);
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

        &-date {

        }

        &-text {
          font-weight: 500;

          &.active {
            color: rgba(215, 0, 0, 1);
          }
        }
      }
    }

    &-name {
      color: rgba(66, 66, 66, 1);
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding: 0 12px 12px 42px;
    }
  }

  &-actions {
    .flex();
    .flex-row();
    .flex-align-items-center();
    .flex-justify-content-space-between();
    background-color: rgba(47, 128, 237, 0.03);
    padding: 12px 12px 12px 42px;

    &-icons {
      .flex();
      .flex-row();
      .flex-align-items-center();
      .flex-justify-content-start();
      gap: 16px;

      div {
        height: 16px;
      }

      .anticon {
        svg {
          fill: #2F80ED;
        }
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: #2F80ED;
        }

        g {
          g {
            rect {
              fill: #2F80ED;
            }
          }
        }
      }
    }

    &-open {
      &-button {
        background-color: rgba(47, 128, 237, 1) !important;
        border-color: rgba(47, 128, 237, 1) !important;
        border-radius: var(--button-border-radius) !important;
        border-style: solid;
        border-width: 1px;
        color: white !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        height: 32px !important;
        line-height: 20px !important;
        padding: 6px 18px !important;

        &.active {
          background-color: white !important;
          color: rgba(47, 128, 237, 1) !important;
        }
      }
    }
  }
}
